<div class="policy-details-container">
  <img src="assets/img/back-square.svg" />
  <div>
    <p class="title caption1--bold">
      {{ trans('CHECKOUT.DYNAMIC_PRICING_CARD.TITLE', { amount: amount ?? 0 }) }}
      <span class="tag caption2--medium">{{ trans('CHECKOUT.DYNAMIC_PRICING_CARD.NEW') }}</span>
    </p>
    <p class="description caption1--regular">
      {{ trans('CHECKOUT.DYNAMIC_PRICING_CARD.DESCRIPTION') }}
      <a [href]="dynamicPricingSectionUrl" class="caption1--regular" target="_blank">
        {{ trans('CHECKOUT.DYNAMIC_PRICING_CARD.LINK') }}
      </a>
    </p>
  </div>
</div>
