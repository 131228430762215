<loader size="lg" [loading]="loading"></loader>

<div class="container">
  <div class="form" *ngIf="!loading">
    <div class="d-flex justify-content-between title">
      <div *ngIf="!isOrderNow" appHoverStyle (click)="backToCart()" class="arrow-back">
        <img loading="lazy" src="../../../assets/img/right-chevron.svg" />
      </div>
    </div>

    <app-info-alert></app-info-alert>

    <form [formGroup]="orderForm" class="form-group" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="row no-gutters">
        <div class="col-12 col-md-7 right-side">
          <p class="headers">{{ 'CHECKOUT.CUSTOMER_INFO' | translate }}</p>
          <div class="row">
            <div class="col col-12">
              <p class="phone-text">{{ 'CHECKOUT.FULL_NAME' | translate }}</p>
              <app-input
                data-test-id="order-full-name"
                ngDefaultControl
                formControlName="receiverName"
                requiredField="true"
                id="receiverName"
                name="receiverName"
                type="text"
                placeholder=""
                [formControl]="orderForm.get('receiverName')"
              >
              </app-input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6 col-12">
              <p class="phone-text">
                {{ 'CHECKOUT.PHONE_NUMBER' | translate }}
                <span class="phone-sub-text">
                  {{ 'CHECKOUT.WITH_ENGLISH_DIGITS_NOTE' | translate }}
                </span>
              </p>
              <app-input
                data-test-id="order-phone-number"
                ngDefaultControl
                formControlName="phoneNumber"
                requiredField="true"
                id="phoneNumber"
                name="phoneNumber"
                type="number"
                placeholder="{{
                  phoneHint || ('CHECKOUT.ALTERNATE_PHONE_NUMBER_PLACEHOLDER' | translate)
                }}"
                [formControl]="orderForm.get('phoneNumber')"
              >
              </app-input>
            </div>
            <div class="col col-md-6 col-12">
              <p class="phone-text">
                {{ 'CHECKOUT.ALTERNATE_PHONE_NUMBER' | translate }}
                <span class="phone-sub-text">
                  {{ 'CHECKOUT.WITH_ENGLISH_DIGITS_NOTE' | translate }}
                  <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span></span
                >
              </p>
              <app-input
                ngDefaultControl
                formControlName="phoneNumber2"
                requiredField="false"
                id="phoneNumber2"
                name="phoneNumber2"
                type="text"
                placeholder="{{ 'CHECKOUT.ALTERNATE_PHONE_NUMBER_PLACEHOLDER' | translate }}"
                [formControl]="orderForm.get('phoneNumber2')"
              >
              </app-input>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <p class="headers" style="margin-top: 41px">
                {{ 'CHECKOUT.SHIPPING_INFO' | translate }}
              </p>
              <p
                class="caption2--regular information-text"
                *ngIf="viewState.shouldShowDetailAddressFields"
              >
                <img src="assets/img/info-circle.svg" />
                {{ 'CHECKOUT.OPTIONAL_INFO' | translate }}
              </p>
              <div class="form-group">
                <p class="phone-text">
                  {{ 'CHECKOUT.PROVINCE' | translate }}
                </p>
                <angular2-multiselect
                  data-test-id="order-province-dropdown"
                  [data]="provinces"
                  [settings]="viewState.provinceDropdownSettings"
                  [formControl]="orderForm.get('province')"
                  (ngModelChange)="onProvinceChange()"
                >
                  <c-item>
                    <ng-template class="province" let-item="item">
                      {{ item.location }}
                    </ng-template>
                  </c-item>
                  <c-badge>
                    <ng-template class="province" let-item="item">
                      {{ item.location }}
                    </ng-template>
                  </c-badge>
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="viewState.shouldShowDetailAddressFields">
            <div class="col col-12">
              <div class="form-group">
                <p class="phone-text">
                  {{ 'CHECKOUT.ZONE' | translate }}
                  <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span>
                </p>
                <angular2-multiselect
                  data-test-id="order-province-dropdown"
                  [data]="provinceZones"
                  [settings]="viewState.zoneDropdownSettings"
                  [formControl]="orderForm.get('zone')"
                  (ngModelChange)="onZoneChange()"
                >
                  <c-item>
                    <ng-template class="province" let-item="item">
                      {{
                        isEnglishLanguageSelected ? item.zoneName?.english : item.zoneName?.arabic
                      }}
                    </ng-template>
                  </c-item>
                  <c-badge>
                    <ng-template class="province" let-item="item">
                      {{
                        isEnglishLanguageSelected ? item.zoneName?.english : item.zoneName?.arabic
                      }}
                    </ng-template>
                  </c-badge>
                </angular2-multiselect>
              </div>
            </div>
            <div class="col col-12">
              <div class="form-group">
                <p class="phone-text">
                  {{ 'CHECKOUT.DISTRICT' | translate }}
                  <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span>
                </p>
                <angular2-multiselect
                  data-test-id="order-province-dropdown"
                  [data]="provinceZoneDistricts"
                  [settings]="viewState.districtDropdownSettings"
                  [formControl]="orderForm.get('district')"
                >
                  <c-item>
                    <ng-template class="province" let-item="item">
                      {{
                        isEnglishLanguageSelected
                          ? item.districtName?.english
                          : item.districtName?.arabic
                      }}
                    </ng-template>
                  </c-item>
                  <c-badge>
                    <ng-template class="province" let-item="item">
                      {{
                        isEnglishLanguageSelected
                          ? item.districtName?.english
                          : item.districtName?.arabic
                      }}
                    </ng-template>
                  </c-badge>
                </angular2-multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <p class="phone-text">
                {{ 'CHECKOUT.DETAILED_ADDRESS' | translate }}
                <span class="phone-sub-text">
                  ({{ 'CHECKOUT.DETAILED_ADDRESS_NOTE' | translate }})
                </span>
              </p>
              <app-input
                data-test-id="order-full-address"
                ngDefaultControl
                formControlName="streetName"
                requiredField="true"
                id="streetName"
                name="streetName"
                type="text"
                placeholder=""
                [formControl]="orderForm.get('streetName')"
              >
              </app-input>
            </div>
          </div>

          <div class="row" *ngIf="orderFromProduct">
            <div class="col col-6">
              <app-input
                ngDefaultControl
                formControlName="quantity"
                requiredField="true"
                label="{{ 'CHECKOUT.QUANTITY' | translate }}"
                id="quantity"
                name="quantity"
                type="number"
                min="1"
                placeholder="{{ 'CHECKOUT.QUANTITY' | translate }}"
                [formControl]="orderForm.get('quantity')"
                (change)="updateQuantity()"
              >
              </app-input>
              <p
                *ngIf="
                  shouldShowStockDistribution &&
                  products[0]?.availabilityInfo?.stockInfo?.remainingStock
                "
                class="quantity-remaining"
              >
                {{ 'CART.REMAINING' | translate }}:
                <span>
                  {{
                    products[0]?.availabilityInfo?.stockInfo?.remainingStock -
                      orderForm.get('quantity')!.value
                  }}</span
                >
              </p>
            </div>
            <div class="col col-6">
              <label class="label-line-height">{{
                'CHECKOUT.SELLING_PRICE_PER_PIECE' | translate
              }}</label>
              <input
                class="form-control"
                [ngClass]="{ 'input-error': isInvalidPrice }"
                type="number"
                id="price"
                formControlName="price"
                [formControl]="orderForm.get('price')"
                (blur)="calculateProfitAndCOD()"
                name="price"
                placeholder="{{ 'CHECKOUT.SELLING_PRICE_PER_PIECE' | translate }}"
              />
              <div>
                <div *ngIf="isInvalidPrice">
                  <p class="input-error">{{ invalidPriceError }}</p>
                </div>
              </div>
            </div>
          </div>

          <div [formGroup]="orderForm.get('orderSource')" class="row">
            <div class="col col-12">
              <p class="phone-text">
                {{ 'CHECKOUT.FACEBOOK_PAGE' | translate }}
                <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span>
              </p>
              <app-input
                ngDefaultControl
                formControlName="pageName"
                requiredField="false"
                id="pageName"
                type="text"
                name="pageName"
                placeholder=""
                [formControl]="orderForm.get('orderSource')!.get('pageName')"
              ></app-input>
            </div>
            <div class="col col-12">
              <p class="phone-text">
                {{ 'CHECKOUT.FACEBOOK_PAGE_LINK' | translate }}
                <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span>
              </p>
              <app-input
                ngDefaultControl
                formControlName="pageUrl"
                requiredField="false"
                id="pageUrl"
                type="text"
                name="pageUrl"
                placeholder=""
                [formControl]="orderForm.get('orderSource')!.get('pageUrl')"
              ></app-input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <div class="form-group">
                <label class="phone-text">
                  {{ 'CHECKOUT.NOTES' | translate }}
                  <span class="phone-2">({{ 'CHECKOUT.OPTIONAL' | translate }})</span>
                </label>
                <textarea class="form-control textarea" formControlName="notes"></textarea>
              </div>
            </div>
          </div>
          <app-order-preference
            *ngIf="shouldShowUpOrderPreference"
            (orderPreferenceProperties)="orderPreferencesChanged($event)"
            [orderPreferenceSource]="'order-page'"
          ></app-order-preference>
        </div>

        <div class="col-12 col-md-5 left-side">
          <div class="left-wrapper">
            <div class="left-header">
              <p class="left-header-text">{{ 'CHECKOUT.ORDER_SUMMARY.TITLE' | translate }}</p>
            </div>
            <div
              *ngFor="let product of products; let index = index"
              class="table-element row no-gutters"
            >
              <div class="col-12 col-md-9 product-info">
                <div
                  class="image-wrap"
                  [routerLink]="['/product-details', product.id]"
                  [queryParams]="{ name: product.productName }"
                  appHoverStyle
                >
                  <img
                    loading="lazy"
                    class="product-image"
                    [src]="product.productPicture || product.image"
                  />
                </div>
                <div class="product-name">
                  <p
                    class="name"
                    [title]="product.productName"
                    [routerLink]="['/product-details', product.id]"
                    [queryParams]="{ name: product.productName }"
                    appHoverStyle
                  >
                    {{ product.productName }}
                  </p>
                  <p class="count-name">
                    {{ 'CHECKOUT.ORDER_SUMMARY.QUANTITY' | translate }}:
                    <span class="count">{{ product.quantity }}x</span>
                  </p>
                  <div class="product-variants">
                    <span *ngIf="product.productColor" class="color-variant">
                      <span class="color-variant-label">
                        {{ 'CHECKOUT.ORDER_SUMMARY.COLOR' | translate }}:
                      </span>
                      <span
                        [ngStyle]="{ backgroundColor: product.productColorHex }"
                        class="color-ball"
                      ></span>
                      <span>{{ product.productColor }}</span>
                    </span>
                    <span *ngIf="product.productSize" class="size-variant">
                      <span class="size-variant-label">
                        {{ 'CHECKOUT.ORDER_SUMMARY.SIZE' | translate }}:
                      </span>
                      <span>{{ product.productSize }}</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="col-3 d-none d-md-flex price-info">
                <p class="prod-price">
                  {{ productListEssentials[index]!.desiredPrice }}
                  <span class="price-text">
                    {{ currency | currencyTranslate | translate | currencyShortName }}
                  </span>
                </p>
                <p class="prod-profit" style="color: #29b6ab">
                  <span class="profit-text">
                    {{ 'CHECKOUT.ORDER_SUMMARY.PROFIT' | translate }}:
                  </span>
                  <br />
                  {{ orderPrice?.products[index]?.finalProfit }}
                  {{ currency | currencyTranslate | translate | currencyShortName }}
                </p>
              </div>
            </div>
            <loader size="sm" [loading]="loadingProfitAndCOD"></loader>
            <ng-container *ngIf="!loadingProfitAndCOD">
              <div class="order-summary">
                <div class="order-summary__row">
                  <p class="order-summary__title">
                    {{ 'CHECKOUT.ORDER_SUMMARY.PRODUCTS_PRICES' | translate }}
                    <span class="order-summary__sub-title">({{ totalProductItems }})</span>
                  </p>
                  <p class="order-summary__value">
                    {{ orderPrice?.totalPrice }}
                    <span class="order-summary__currency">
                      {{ currency | currencyTranslate | translate | currencyShortName }}
                    </span>
                  </p>
                </div>
                <div class="order-summary__row">
                  <p class="order-summary__title">
                    {{
                      (shouldShowVAT
                        ? 'CHECKOUT.ORDER_SUMMARY.PRODUCTS_PROFIT'
                        : 'CHECKOUT.ORDER_SUMMARY.NET_PROFIT'
                      ) | translate
                    }}
                  </p>
                  <p
                    [ngClass]="
                      shouldShowVAT ? 'order-summary__value' : 'order-summary__value--green'
                    "
                  >
                    {{ orderPrice?.totalProfit }}
                    <span class="order-summary__currency">
                      {{ currency | currencyTranslate | translate | currencyShortName }}
                    </span>
                  </p>
                </div>
                <ng-container *ngIf="shouldShowVAT">
                  <div class="order-summary__row">
                    <p class="order-summary__title">
                      {{ 'CHECKOUT.ORDER_SUMMARY.VAT' | translate }}
                    </p>
                    <p class="order-summary__value">
                      {{ orderPrice?.totalVat }}{{ orderPrice?.totalVat > 0 ? '-' : '' }}
                      <span class="order-summary__currency">
                        {{ currency | currencyTranslate | translate | currencyShortName }}
                      </span>
                    </p>
                  </div>
                  <div class="order-summary__row">
                    <p class="order-summary__title">
                      {{ 'CHECKOUT.ORDER_SUMMARY.NET_PROFIT' | translate }}
                    </p>
                    <p class="order-summary__value order-summary__value--green">
                      {{ orderPrice?.totalNetProfit }}
                      <span class="order-summary__currency">
                        {{ currency | currencyTranslate | translate | currencyShortName }}
                      </span>
                    </p>
                  </div>
                </ng-container>
                <div class="order-summary__row">
                  <p class="order-summary__title">
                    {{ 'CHECKOUT.ORDER_SUMMARY.SHIPPING_COST' | translate
                    }}<span
                      class="body1--medium content-medium-color"
                      *ngIf="orderPreference?.isFreeShippingPreferred"
                      >{{ 'ORDER_PREFERENCE.SHIPPING_FEES_ADDED' | translate }}</span
                    >
                  </p>
                  <p class="order-summary__value">
                    {{ shippingCost }}
                    <span class="order-summary__currency">
                      {{ currency | currencyTranslate | translate | currencyShortName }}
                    </span>
                  </p>
                </div>
                <div
                  class="order-summary__row order-summary__final-row"
                  *ngIf="shouldShowQuantityDiscount"
                >
                  <p class="order-summary__title">
                    <img src="assets/img/price-tag.svg" alt="price-tag" />
                    {{ 'CART.CART_SUMMARY.QUANTITY_DISCOUNT' | translate }}
                  </p>
                  <p class="order-summary__value text-success">
                    {{ cartData?.totalQuantityDiscount || totalQuantityDiscountApplied }}
                    <span class="order-summary__currency">
                      {{ currency | currencyTranslate | translate | currencyShortName }}
                    </span>
                  </p>
                </div>

                <p *ngIf="!province.pristine" class="delivery"></p>
                <div class="order-summary__row order-summary__final-row">
                  <p class="order-summary__title">
                    {{ 'CHECKOUT.ORDER_SUMMARY.CASH_ON_DELIVERY' | translate }}
                  </p>
                  <p class="order-summary__value">
                    {{ orderPrice?.totalPrice + shippingCost }}
                    {{ currency | currencyTranslate | translate | currencyShortName }}
                  </p>
                </div>
              </div>
              <app-return-policy-details
                *ngIf="dynamicPricingEnabled"
                [amount]="shippingReturnCost"
              ></app-return-policy-details>
              <button
                data-test-id="place-order-btn"
                class="btn mat-raised-button order-btn"
                type="submit"
                [disabled]="
                  isInvalidPrice || orderForm.invalid || !isShippingCalculated || didCheckout
                "
              >
                {{ 'CHECKOUT.ORDER_SUMMARY.SUBMIT' | translate }}
              </button>
            </ng-container>
          </div>
          <app-up-selling-feedback *ngIf="shouldShowUpSellingFeedback"></app-up-selling-feedback>
        </div>
      </div>
    </form>
  </div>
</div>
